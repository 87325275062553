import { AxiosResponse } from "axios";

import client from "../client";
import { postLoginGoogleConfig } from "./postLoginGoogleConfig";
import { PostLoginGooglePayload } from "./types";
import { PostLoginResponseData } from "../postLogin/types";

const postLoginGoogle = async (
  data?: PostLoginGooglePayload,
  reference?: string
): Promise<AxiosResponse<PostLoginResponseData>> => {
  return await client({
    ...postLoginGoogleConfig,
    data: { reference, ...data },
  });
};

export default { postLoginGoogle };
