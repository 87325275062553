import { AxiosResponse } from "axios";

import client from "../client";
import { postRegisterGoogleConfig } from "./postRegisterGoogleConfig";
import { PostRegisterGooglePayload } from "./types";
import { PostRegisterResponseData } from "../postRegister/types";

const postRegisterGoogle = async (
  data?: PostRegisterGooglePayload
): Promise<AxiosResponse<PostRegisterResponseData>> =>
  await client({ ...postRegisterGoogleConfig, data });

export default { postRegisterGoogle };
