import { UserContextProps } from "@/contexts/UserContext/types";
import { I_PrincingPage } from "@/data/types/pages";
import { PlanProps } from "@/types/plan";
import { SubscriptionPlan } from "@/types/types";

export interface ISubscriptionPlansContainer {
  data: I_PrincingPage;
  plans: PlanProps[];
}

export interface IPlansManager {
  data: I_PrincingPage;
  currentPlan: SubscriptionPlan;
  userContext: UserContextProps;
}

export const CANCELLED_SUBSCRIPTION = "cancelledSubscription";