import { AxiosResponse } from "axios";
import client from "../client";
import { postRegisterConfig } from "./postRegisterConfig";
import { PostRegisterPayload, PostRegisterResponseData } from "./types";

const postRegister = async (
  data?: PostRegisterPayload,
  reference?: string
): Promise<AxiosResponse<PostRegisterResponseData>> =>
  await client({ ...postRegisterConfig, data: { reference, ...data } });

export default { postRegister };
