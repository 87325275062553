"use client";

import React from "react";
import { useTranslation } from "next-i18next";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { Form } from "@/components/Form";
import { Typography } from "@/components/Typography";
import { Button } from "@/components/Button";
import { GoogleAuthButton } from "@/components/GoogleAuthButton";

import { registerValidation } from "@/utils/validators/register";

import { useToast } from "@/hooks/useToast";
import { useRegister } from "@/hooks/auth/useRegister";
import { QREditorContext } from "@/contexts/QREditorContext";

import { GTM_EVENT, GTM_EVENT_NAME } from "@/internals/constants/events";

import { RegisterFormProps } from "./types";
import { FormValidationMethods } from "@/components/Form/types";

import "./styles.scss";

export default function Register({
  onSetFormType,
  onRegister,
  source,
  registerData,
}: RegisterFormProps) {
  const { toast } = useToast();
  const { t } = useTranslation();

  const qrEditorContext = React.useContext(QREditorContext);
  const {
    onHandleRegister,
    isRegisterLoading,
    registerResponse,
    registerError,
  } = useRegister();

  const [isGoogleLoginLoading, setIsGoogleLoginLoading] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [formObject, setFormObject] = React.useState<
    { values: FieldValues; methods: UseFormReturn } | undefined
  >(undefined);

  const schema = registerValidation(registerData, password);

  const {
    richtext: { Tag, content, className },
    button: { id, label },
  } = registerData.authModeSwitcher;

  const onRegisterSubmit = async (
    values: FieldValues,
    methods: UseFormReturn
  ) => {
    setFormObject({ values, methods });
    onHandleRegister({
      email: values.email,
      password: values.password,
      reference: qrEditorContext?.qrData?.qrCodeReference,
      // reference: undefined,
    });
  };

  const onGoogleAuthSuccess = () => {
    window?.dataLayer?.push({
      event: GTM_EVENT_NAME.SIGN_UP_COMPLETED,
      source,
    } as GTM_EVENT);
    onRegister.onFormSuccess();
  };

  React.useEffect(() => {
    if (!formObject || isRegisterLoading) return;
    if (registerResponse?.status === 200 || registerResponse?.status === 201) {
      window?.dataLayer?.push({
        event: GTM_EVENT_NAME.SIGN_UP_COMPLETED,
        source,
      } as GTM_EVENT);
      onRegister.onFormSuccess(formObject.values, formObject.methods);
    }
  }, [registerResponse]);

  React.useEffect(() => {
    if (!formObject) return;

    if (registerError?.status === 400 || registerError?.status === 401) {
      formObject.methods.setError("email", {
        message: registerData.invalidAuthMessage,
      });
      formObject.methods.resetField("password", { defaultValue: "" });
    } else {
      toast({ message: "genericError", type: "error" });
    }
  }, [registerError]);

  const checkPassword = (value: string, methods: FormValidationMethods) => {
    setPassword(value);
    methods.trigger("password");
  };

  return (
    <div className="Register" data-testid="Register">
      <Typography Tag="p" classname="Register__title" size="xl" spacing={5}>
        {registerData.title}
      </Typography>
      <Form
        fields={registerData.formFields}
        onSubmit={onRegisterSubmit}
        schema={schema}
        validationMode="onSubmit"
        isDisabled={isGoogleLoginLoading}
        isLoading={isRegisterLoading}
        onWatchFields={({ field, value, methods }) => {
          if (field === "password") checkPassword(value, methods);
        }}
        isAuth
      />
      <GoogleAuthButton
        type="signup"
        label="Continue with Google"
        isLoading={setIsGoogleLoginLoading}
        onSuccess={onGoogleAuthSuccess}
        isFormLoading={isRegisterLoading}
        errors={{
          registerError: registerData.invalidAuthGoogleMessage,
          genericError: t("_genericError"),
        }}
      />
      <div className="Auth__switcher">
        <Typography Tag={Tag} spacing={2} classname={className}>
          {content}
        </Typography>
        <Button
          ghost
          spacing={2}
          buttonId={id}
          label={label}
          disabled={isGoogleLoginLoading}
          dataQA="register-modal-login-button"
          onClick={() => onSetFormType("login")}
          className="switch-form-button"
        />
      </div>
      <div className="register-disclaimer">
        <span
          dangerouslySetInnerHTML={{ __html: registerData.disclaimer }}
        ></span>
      </div>
    </div>
  );
}
