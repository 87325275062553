import { AxiosRequestConfig } from "axios";

export const postRegisterGoogleConfig: AxiosRequestConfig = {
  method: "post",
  url: "/user",
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
};
