import { Fragment } from "react";
import { ReactSVG } from "react-svg";

import { Typography } from "@/components/Typography";

import { ModalProps } from "@/contexts/ModalContext/types";
import { SuccessRecoveryPasswordModalProps } from "./types";

export const getSuccessRecoveryPasswordModal = (
  props: ModalProps<SuccessRecoveryPasswordModalProps>
) => {
  const { data, onConfirmButton } = props;

  return (
    <Fragment>
      <ReactSVG
        className="u-spacing-5"
        data-testid="email-send-img"
        src={data.successModal.image}
      />

      {data.successModal.richtext.map((item, i) => {
        return (
          <Typography key={i} {...item} Tag={item.tag}>
            {item.content}
          </Typography>
        );
      })}
      <span className="button" onClick={onConfirmButton}>
        {data.successModal.button.label}
      </span>
    </Fragment>
  );
};
