import React from "react";

import { Auth } from "@/components/Auth";
import { AuthFormProps } from "@/components/Auth/types";

import { GTM_EVENT_SOURCE } from "@/internals/constants/events";

import "./styles.scss";

export const getAuthModal = (props: AuthFormProps) => {
  const { formType, isLoading, onLogin, onRegister, onRecover, modalData } =
    props;

  return (
    <Auth
      source={GTM_EVENT_SOURCE.MODAL}
      formType={formType || "register"}
      onLogin={{
        onFormSuccess: onLogin.onFormSuccess,
        data: onLogin.data,
      }}
      onRegister={{
        onFormSuccess: onRegister.onFormSuccess,
        data: onRegister.data,
      }}
      onRecover={{
        onFormSuccess: onRecover.onFormSuccess,
        data: onRecover.data,
      }}
      isLoading={isLoading}
      modalData={modalData}
    />
  );
};
