import { FormValidationSchema } from "@/components/Form/types";
import { LoginDataProps } from "@/data/types/login";
import { getRuleErrorMessage } from "@/utils/formHelper";

export const loginValidation = (
  data: LoginDataProps
): FormValidationSchema[] => [
  {
    name: "email",
    rules: {
      required: {
        value: true,
        message: getRuleErrorMessage("email", "required", data),
      },
    },
  },
  {
    name: "password",
    rules: {
      required: {
        value: true,
        message: getRuleErrorMessage("password", "required", data),
      },
    },
  },
];
