import { AxiosResponse } from "axios";

import client from "../client";
import { postRecoverPasswordConfig } from "./postRecoverPasswordConfig";
import {
  PostRecoverPasswordPayload,
  PostRecoverPasswordResponse,
} from "./types";

const postRecoverPassword = async (
  data?: PostRecoverPasswordPayload
): Promise<AxiosResponse<PostRecoverPasswordResponse>> =>
  await client({ ...postRecoverPasswordConfig, data: { ...data } });

export default { postRecoverPassword };
