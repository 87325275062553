"use client";

import React, { useContext } from "react";
import { useTranslation } from "next-i18next";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { GoogleAuthButton } from "@/components/GoogleAuthButton";
import { Typography } from "@/components/Typography";
import { Form } from "@/components/Form";
import { Button } from "@/components/Button";

import { QREditorContext } from "@/contexts/QREditorContext";
import { loginValidation } from "@/utils/validators/login";
import { onBindClick } from "@/utils/formHelper";

import { useLogin } from "@/hooks/auth/useLogin";
import { useToast } from "@/hooks/useToast";
import { LoginFormProps } from "./types";

import "./styles.scss";

export default function Login({
  onSetFormType,
  onLogin,
  loginData,
}: LoginFormProps) {
  const { toast } = useToast();
  const { t } = useTranslation();
  const qrEditorContext = useContext(QREditorContext);

  const [isGoogleLoginLoading, setIsGoogleLoginLoading] = React.useState(false);

  onBindClick(loginData?.formFields, "forgotPasswordLink", () =>
    onSetFormType("recoverPassword")
  );

  const schema = loginValidation(loginData);
  const { onHandleLogin, isLoginLoading, loginResponse, loginError } =
    useLogin();

  const {
    richtext: { Tag, content, spacing, className },
    button: { id, label },
  } = loginData?.authModeSwitcher || {};

  const [formObject, setFormObject] = React.useState<
    { values: FieldValues; methods: UseFormReturn } | undefined
  >(undefined);

  const onLoginRequest = (values: FieldValues) =>
    onHandleLogin({
      email: values.email,
      password: values.password,
      reference: qrEditorContext?.qrData?.qrCodeReference,

      // reference: undefined,
    });

  React.useEffect(() => {
    if (formObject && loginResponse?.status === 200 && !isLoginLoading) {
      onLogin.onFormSuccess(formObject.values, formObject.methods);
    }
  }, [loginResponse]);

  React.useEffect(() => {
    if (formObject && loginError) {
      if (loginError.status === 400 || loginError.status === 401) {
        formObject.methods.setError("email", {
          message: loginData.invalidAuthMessage,
        });
        formObject.methods.resetField("password", { defaultValue: "" });
      } else {
        toast({ message: "genericError", type: "error" });
      }
    }
  }, [loginError]);

  const onLoginSubmit = (values: FieldValues, methods: UseFormReturn) => {
    setFormObject({ values, methods });
    onLoginRequest(values);
  };

  return (
    <div className="Login" data-testid="Login">
      <Typography Tag="p" classname="Login__title" size="xl" spacing={5}>
        {loginData.title}
      </Typography>
      <Form
        fields={loginData.formFields}
        onSubmit={onLoginSubmit}
        schema={schema}
        validationMode="onSubmit"
        isDisabled={isGoogleLoginLoading}
        isLoading={isLoginLoading && ["email", "password", "login"]}
        isAuth
      />
      <GoogleAuthButton
        type="signin"
        label="Continue with Google"
        isLoading={setIsGoogleLoginLoading}
        isFormLoading={isLoginLoading}
        onSuccess={() => onLogin.onFormSuccess()}
        errors={{
          loginError: loginData.invalidAuthGoogleMessage,
          genericError: t("_genericError"),
        }}
      />
      <div className="Auth__switcher">
        <Typography Tag={Tag} spacing={spacing} classname={className}>
          {content}
        </Typography>
        <Button
          ghost
          buttonId={id}
          spacing={5}
          label={label}
          disabled={isGoogleLoginLoading}
          dataQA="login-modal-register-button"
          onClick={() => onSetFormType("register")}
        />
      </div>
    </div>
  );
}
